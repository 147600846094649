var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section mb-12"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('div',{staticClass:"mt-3"},[_c('v-btn',{attrs:{"color":"success","outlined":"","height":"40px"},on:{"click":_vm.createCurrency}},[_vm._v(" "+_vm._s(_vm.$t('Add token'))+" ")])],1)],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"update:selection":function($event){_vm.selection=$event}},scopedSlots:_vm._u([{key:"UniqAddress",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.uniqueAddress ? _vm.$t('Yes') : _vm.$t('No')))])]}},{key:"currency",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.getIconUrl(row))?_c('div',{staticClass:"mr-3"},[_c('img',{staticClass:"d-flex section__icon",staticStyle:{"width":"32px"},attrs:{"src":_vm.getIconUrl(row)}})]):_vm._e(),_c('div',[_c('div',{staticStyle:{"line-height":"1.25"}},[_vm._v(" "+_vm._s(row.name)+" ")]),(row.id)?_c('div',{staticClass:"text--disabled",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(row.title)+" ")]):_vm._e()])])]}},{key:"network",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getNetworkTitle(row))+" ")]}},{key:"address",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-truncate"},[_c('v-tooltip',{attrs:{"top":"","max-width":"600px","transition":"fade-transition","z-index":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){return _vm.copy(row.address)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.address)+" ")])]}}],null,true)},[_c('div',[_vm._v(_vm._s(row.address))])])],1)]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.editCurrency(row)}}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.deleteCurrency(row)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}])})],1),_c('Modal',{attrs:{"data":_vm.editingToken.data,"is-creating":_vm.editingToken.isCreating,"networks":_vm.networks},on:{"apply":_vm.applyEdit},model:{value:(_vm.editingToken.modal),callback:function ($$v) {_vm.$set(_vm.editingToken, "modal", $$v)},expression:"editingToken.modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }