export default [
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'slot',
      name: 'currency',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Confirmations / For Use',
    },
    cell: {
      type: 'text',
      value: (e) => `${e.minConfirmations} / ${e.minConfirmationsForUse}`,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Tag',
    },
    cell: {
      type: 'slot',
      name: 'tag',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Address Tag Only',
    },
    cell: {
      type: 'slot',
      name: 'tagOnly',
    },
  },
  {
    width: 40,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
