export default [
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Token',
    },
    cell: {
      type: 'slot',
      name: 'currency',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Network',
    },
    cell: {
      type: 'slot',
      name: 'network',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Address',
    },
    cell: {
      type: 'slot',
      name: 'address',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Unique Address',
    },
    cell: {
      type: 'slot',
      name: 'UniqAddress',
    },
  },

  {
    width: 23,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
