//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Filters from './TableFilters.vue';
import Modal from './Modal.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters, Modal },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      editingCurrency: {
        modal: false,
        data: {},
        isCreating: false,
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('paymentsAndCurrenciesNetworks', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('paymentsAndCurrenciesNetworks', {
      loadDataAction: 'loadData',
      createCryptoCurrencyAction: 'createCryptoCurrency',
      editCryptoCurrencyAction: 'editCryptoCurrency',
      deleteCryptoCurrencyAction: 'deleteCryptoCurrency',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getIconUrl(row) {
      const icon = row.icons?.[0];
      if (icon) return icon.includes('://') ? icon : `${constant.settings.EXCHANGE_URL}${icon}`;
      return null;
    },

    editCurrency(row) {
      this.editingCurrency.modal = true;
      this.editingCurrency.data = { ...row };
      this.editingCurrency.isCreating = false;
    },

    createCurrency() {
      this.editingCurrency.modal = true;
      this.editingCurrency.data = {};
      this.editingCurrency.isCreating = true;
    },

    applyEdit({ input }) {
      const { isCreating } = this.editingCurrency;
      this.confirmAction({
        title: isCreating
          ? 'Are you sure you want to create network?'
          : 'Are you sure you want to edit network?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            if (isCreating) {
              await this.createCryptoCurrencyAction(input);
            } else {
              await this.editCryptoCurrencyAction(input);
            }
            this.editingCurrency.modal = false;
            await this.loadDataAction({});
            if (isCreating) {
              this.setSuccessNotification('Network created');
            } else {
              this.setSuccessNotification('Network edited');
            }
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    deleteCurrency(row) {
      this.confirmAction({
        title: 'Are you sure you want to delete network?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.deleteCryptoCurrencyAction(row.id);
            await this.loadDataAction({});
            this.setSuccessNotification('Network deleted');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },
  },
};
