//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      input: {},
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    defaultInput() {
      return {
        name: '',
        title: '',
        uId: '',
        tag: false,
        tagName: '',
        tagMultiline: false,
        tagRegex: '',
        uriAmount: false,
        addressMultiple: false,
        addressPublicKey: false,
        addressTagOnly: false,
        addressRegex: '',
        addressTokens: false,
        url: '',
        urlTx: '',
        urlAddress: '',
        minConfirmations: 0,
        minConfirmationsForUse: 0,
      };
    },
  },

  watch: {
    value(val) {
      if (val) {
        const { isCreating, defaultInput, data } = this;
        if (isCreating) {
          this.$set(this, 'input', defaultInput);
        } else {
          const model = { id: data.id };
          Object.keys(defaultInput).forEach((key) => {
            model[key] = data[key];
          });
          this.$set(this, 'input', model);
        }
      }
    },
  },

  methods: {
    apply() {
      const { input } = this;
      this.$emit('apply', { input });
    },
  },
};
