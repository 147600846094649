var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section mb-12"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('div',{staticClass:"mt-3"},[_c('v-btn',{attrs:{"color":"success","outlined":"","height":"40px"},on:{"click":_vm.createCurrency}},[_vm._v(" "+_vm._s(_vm.$t('Add currency'))+" ")])],1)],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"update:selection":function($event){_vm.selection=$event}},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
var row = ref.row;
return [_c('p',{class:row.tag ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(row.tag ? _vm.$t('Yes') : _vm.$t('No'))+" ")])]}},{key:"tagOnly",fn:function(ref){
var row = ref.row;
return [_c('p',{class:row.addressTagOnly ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(row.addressTagOnly ? _vm.$t('Yes') : _vm.$t('No'))+" ")])]}},{key:"currency",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.getIconUrl(row))?_c('div',{staticClass:"mr-3"},[_c('img',{staticClass:"d-flex section__icon",staticStyle:{"width":"32px"},attrs:{"src":_vm.getIconUrl(row)}})]):_vm._e(),_c('div',[_c('div',{staticStyle:{"line-height":"1.25"}},[_vm._v(" "+_vm._s(row.name)+" ")]),(row.id)?_c('div',{staticClass:"text--disabled",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(row.title)+" ")]):_vm._e()])])]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.editCurrency(row)}}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.deleteCurrency(row)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}])})],1),_c('Modal',{attrs:{"data":_vm.editingCurrency.data,"is-creating":_vm.editingCurrency.isCreating},on:{"apply":_vm.applyEdit},model:{value:(_vm.editingCurrency.modal),callback:function ($$v) {_vm.$set(_vm.editingCurrency, "modal", $$v)},expression:"editingCurrency.modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }